<template>
  <SiteMain>
    <PageLayout container>
      <header class="page-header">
        <h2>{{ $t('links.legal') }}</h2>
      </header>
      <section class="page-content--block">
        <div class="block-wrapper" v-html="page.legal_notice"></div>
      </section>
    </PageLayout>
  </SiteMain>
</template>

<script>
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {
    SiteMain,
    PageLayout,
    ContactForm,
  },
  data() {
    return {};
  },
  computed: {
    page() {
      return this.$store.state.cache.data[`login${this.$i18n.locale}`]?.page ?? '';
    },
  },
  methods: {
    async loadPage() {
      const key = `login${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=login`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss">
.page-layout {
  .page-header {
    padding-bottom: 10px;

    p {
      color: white;
    }
  }

  .page-content--block {
    .block-wrapper {
      padding: 0;

      ul li {
        margin-bottom: 10px;
        list-style: unset;
        font-size: 1rem;
        color: #a4aaae;

        @include tablet-down {
          text-align: left;
        }
      }
    }
  }
}
</style>
